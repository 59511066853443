<template>
    <user-dashboard-layout>
        <v-container fluid class="fill-height p-5">
            <v-card class="mx-auto full-width">
                <v-card-text>
                    <v-row>
                        <v-col>
                            <avatar-edit
                                v-model="user.avatar_url"
                                :current_url="user.avatar_url"
                                @input="handleUpdateProfile"
                            />
                        </v-col>
                        <v-col>
                            <v-form>
                                <v-text-field
                                    v-model="user.name"
                                    label="Name"
                                    placeholder=""/>

                                <v-btn
                                    @click="handleUpdateProfile"
                                    v-text="'Update'"/>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import UserDashboardLayout from "../../layouts/UserDashboardLayout";
    import User from "../../models/User";
    import AvatarEdit from "../../components/AvatarEdit";
    export default {
        name: "Profile",
        components: {AvatarEdit, UserDashboardLayout},
        data: function () {
            return {
                user: {},
            }
        },
        methods: {
            async handleUpdateProfile() {
                const user = new User(this.user);
                this.user = await user.save()
            }
        },
        mounted() {
            this.user = this.$auth.user()
        }
    }
</script>

<style scoped>
.full-width {
    width: 100%;
}
</style>
